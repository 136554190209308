// Returns true if bottom edge of an element is visible
import { Optional } from '@avvoka/shared'
import { parseMatch } from './QuestionnaireHelpers'

export function isBottomEdgeVisible(element: HTMLElement, bias = 1): boolean {
  return element.scrollHeight - element.scrollTop - element.clientHeight < bias
}

// Returns true if element is scrollable in X direction
export function isScrollableX(element: HTMLElement) {
  const hasScrollableContent = element.scrollWidth > element.clientWidth
  const hasScrollHidden =
    window.getComputedStyle(element).overflowX === 'hidden'
  return hasScrollableContent && !hasScrollHidden
}

// Returns true if element is scrollable in Y direction
export function isScrollableY(element: HTMLElement) {
  return element.scrollHeight > element.clientHeight
}

// Returns true if element is scrollable in any direction
export function isScrollable(element: HTMLElement) {
  return isScrollableY(element) || isScrollableX(element)
}

// calls provided callback on DOMContentLoaded event
export function onDomContentLoaded(callback: () => unknown) {
  if (document.readyState === 'complete') callback()
  else window.addEventListener('DOMContentLoaded', callback)
}

export function DOMEmit<EventName extends Frontend.Events.EventName>(
  eventName: EventName,
  detail?: Frontend.Events.Listeners[EventName]
) {
  const event = new CustomEvent(eventName, { detail })
  document.dispatchEvent(event)
}

export function DOMListen<EventName extends Frontend.Events.EventName>(
  eventName: EventName,
  callback: (event: { detail: Frontend.Events.Listeners[EventName] }) => unknown
) {
  document.addEventListener(eventName as string, callback as VoidFunction)
}

export function setURLTab(tab: string, input?: HTMLInputElement) {
  const url = new URL(window.location.href)
  url.searchParams.set('tab', tab)
  if (input) input.value = tab
  window.history.pushState({}, '', url.toString())
}

export function getURLTab() {
  const url_string = window.location.href
  const url = new URL(url_string)
  return url.searchParams.get('tab')
}

export const isHTMLButtonElement = (
  element: unknown
): element is HTMLButtonElement => {
  return element instanceof HTMLButtonElement
}

export function IsHTMLElement(element: unknown): element is HTMLElement {
  return element instanceof HTMLElement
}

export function IsHTMLInputElement(
  element: unknown
): element is HTMLInputElement {
  return element instanceof HTMLInputElement
}

export const getElementByIdMaybe = <T extends Node = HTMLElement>(
  id: string
): Optional<T> => {
  return Optional.ofNullable(document.getElementById(id) as T)
}

export const getFormFromElementMaybe = (
  element: HTMLElement | HTMLInputElement | EventTarget | null | undefined
): Optional<HTMLFormElement> => {
  if (element == null) return Optional.empty()
  else if ('form' in element) return Optional.of(element.form)
  else if ('closest' in element)
    return Optional.ofNullable(element.closest('form'))
  else return Optional.empty()
}

export const toPlainText = (str: string, withHtml = false) => {
  // removes any dynamic attributes from the string {{AttDefault: 'hello world'}} etc.
  const converter = new showdown.Converter({ openLinksInNewWindow: true })

  function extractContent(s: string, space: boolean) {
    const span = document.createElement('span')
    span.innerHTML = s
    if (space) {
      const children = span.querySelectorAll('*')
      for (var i = 0; i < children.length; i++) {
        if (children[i].textContent) children[i].textContent += ' '
        else children[i].innerText += ' '
      }
    }

    let result = [span.textContent || span.innerText]
      .toString()
      .replace(/Attdefault/g, 'Att_default')
    const regExp = /{{'Att_default': \['.+?', '.+?']}}/g

    const matches = result.match(regExp)
    if (!matches) return result.replace(/ +/g, ' ')

    matches.forEach((match: string) => {
      const values = parseMatch(match, false)
      if (!values) return result.replace(/ +/g, ' ')

      const plainText = `${values[0]} or ${values[1]}`
      const html = `&#8203;<span class="dynamic-label" contenteditable="false">${values[0]} or ${values[1]}</span>&#8203;`
      result = result.replace(match, withHtml ? html : plainText)
    })

    return result
  }

  return extractContent(
    converter.makeHtml(str.replace(/<p>/g, '').replace(/<\/p>/g, '\n')),
    false
  )
}
