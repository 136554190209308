import {handleEditorStyles} from "./editor/styles";
import Utils from "./utils";
import consumer from "../channels/consumer"
import { dsSplit } from "./questionnaire/types/datasheets/utils"
import { DOMEmit } from "./dom_utils";
import type {IQuestionnaireStore, QStoreContext} from "./QuestionnaireUtils";
import {getSaveEntryDocumentID, uniqueSharedRepeaters} from "./template_packs/save_entry_utils";
import { useTemplateVersionStore } from "@stores/generic/templateVersion.store";
import { getActivePinia } from "pinia";

const axios = Utils.axios

export type Party = [string, string, string, IRights]
export interface IRights {
    edit: string
    approve: string
    invite: string
    sign: string
    default_email: string
    force_add: string
}

interface IChangedPartyNames {
    newPartyName: string
    oldPartyName?: string
}

export const UNALLOCATED_QUESTIONNAIRE = 'Unallocated'

export const findChangedParty = (oldPartiesNames: string[], newPartiesNames: string[]):string | null | IChangedPartyNames | undefined => {
  if(oldPartiesNames.length < newPartiesNames.length){
    const addedPartyName = newPartiesNames.find(partyName => oldPartiesNames.indexOf(partyName) === -1)
    return addedPartyName
  } else {
    const newPartyName = newPartiesNames.find(partyName => oldPartiesNames.indexOf(partyName) === -1)
    if(newPartyName == null) return null
    const oldPartyName = oldPartiesNames.find(partyName => newPartiesNames.indexOf(partyName) === -1)
    return { newPartyName, oldPartyName}
  }
}

export const currencySplitter = '_avvCurrencySplit_'
export const dsSplitter = '#='

export function deleteQuestionnaire(store: QStoreContext, partyToRealocate: string){
  store.commit('DELETE_QUESTIONNAIRE', store.state.selected_party)
  if(partyToRealocate){
    const questions = store.state.questions.filter((q: Backend.Questionnaire.IQuestion) => q.party === store.state.selected_party)
    questions.forEach(q => {
      store.commit("REPLACE_QUESTION", {
        oldQuestion: q,
        newQuestion: {...q, party: partyToRealocate}
      })
    })
  }
}

export const saveEntry = ({state, dispatch, commit}: {state: IQuestionnaireStore, dispatch: any, commit: any}, att: string, value: string | string[], succesion: number | null = null) => {

  const question = state.questions.find((q: Backend.Questionnaire.IQuestion) => q.att === att)
  if(!question) {
    const loopCount = typeof succesion == 'number' ? succesion + 1 : null
    dispatch('save_entry', {att, start: false, loopCount})
    return
  }
  const data: Record<string, any> = {
    value,
    type: question.type,
    entry_name: window.AvvParser.unicode(att),
    send_agreement: state.show_editor,
    id: state.document_id
  }

  const repeaterID = question.opts['repeater-id'] as string | undefined
  const isCurrency = question.type === 'currency'

  if(state.created_from_pack) {
    data.id = getSaveEntryDocumentID(AvvStore.state.documents_by_attributes, AvvStore.state.loopIdsByDoc, state.document_id, att, repeaterID)
  }

  if(repeaterID) {
    data.isRepeater = true
    data.repeater = repeaterID
    data.succession = succesion
    if(Array.isArray(data.value)) data.value = data.value[data.succession]
  }

  const valueAndModificator = getValueAndModificator(data.value as string, question)
  data.value = valueAndModificator.value
  data.modificator = valueAndModificator.modificator

  const url = AvvStore.state.save_entry_url || '/documents/save_entry'
  const previouslySavedValue = Utils.notANumber(succesion) ? state.last_saved_entries[att] : (state.last_saved_entries[att] ? state.last_saved_entries[att][succesion] : undefined)
  const isReset = typeof previouslySavedValue == 'undefined' && data.value == ""
  if((compareValues(previouslySavedValue, data.value, 'dq') && !isCurrency) && !isReset) {
    dispatch('save_entry', {att, start: false, loopCount: succesion === -1 ? null : succesion + 1})
    return
  }
  // console.info('SAVE ENTRY', data)
  axios.post(url, data)
    .then(result => {
      commit('SET_QUESTIONNAIRE_DATA', result.data.questionnaire_data)
      if(result.data.sidekiq_status) commit('SET_SIDEKIQ_STATUS', {sidekiq_status: result.data.sidekiq_status, immediate: true})
      commit('ADD_LAST_SAVED_ENTRY', {att, loopCount: succesion === -1 ? null : succesion + 1, value: data.value})
      DOMEmit('agreement_html:update', {html: result.data.agreement_html, documentID: data.id})
      if(!AvvStore.state.is_doc_negotiable && !AvvStore.state.is_forms) {
        handleEditorStyles(
          useTemplateVersionStore(getActivePinia()),
          document.querySelector(`#q-editor-${data.id} .avv-container`)
        )
      }
      return
    })
    .then(() => dispatch('save_entry', {att, start: false, loopCount: succesion + 1}))
}

export const getCurrentQuestionnaireData = async (): Promise<{valid_conditions: Record<string, number[]>, loop_counts: Record<string, number>}> => {
  const document_id = AvvStore.state.document_id
  const url = AvvStore.state.questionnaire_data_url || `/documents/${document_id}/questionnaire_data`
  const response = await axios.get(url)
  return response.data.questionnaire_data
}

export const isFromRepeater = (q, repeater) => {
  if(!repeater || !q) return false
  return ['repeater-id', 'repeater-master-id'].some(key => q.opts[key] === repeater)
}

const shouldMerge = (q1, q2, store) => {
  if(q1.opts['repeater-merge'] || q2.opts['repeater-merge']) return true
  const masterID = q1.opts['repeater-master-id'] ?? q2.opts['repeater-master-id']
  const masterQuestion = store.state.questions.find(q => q.opts['repeater-id'] === masterID)
  if(masterQuestion) return masterQuestion.opts['repeater-merge']
  return false
}

export const areInSameRenderedRepeater = (q1, q2, store) => {
  if(shouldMerge(q1, q2, store)) return isFromRepeater(q2, q1.opts['repeater-id']) || isFromRepeater(q2, q1.opts['repeater-master-id'])
  else return q2.opts['repeater-id'] == q1.opts['repeater-id'] || uniqueSharedRepeaters(q2.opts['repeater-id'], AvvStore.state.loopIdsByAttsFromDocuments).includes(q1.opts['repeater-id'])
}

export const listenOnStatusChanges = (store, id?: string) => {
  const documentID = id ? id : String(store.state.document_id)
  consumer.subscriptions.create({channel: "DocumentChannel", id: documentID}, {
    received(response) {
        switch (response.type) {
          case 'nego_state':
            store.commit('SET_SIDEKIQ_STATUS', {sidekiq_status: response.data.state})
            break;
          case 'entries_update':
            store.commit('UPDATE_DQ_LIVE', response.data)
            break;
          case 'question_update':
            store.commit('SET_QUESTION_OPTIONS', response.data)
            break;
        }
    }
  });
}

const attributeChangeListeners = {}

export const onAttributeChange = (att: string, fnc: (loopCount: number) => void) => {
  if(!attributeChangeListeners[att]) attributeChangeListeners[att] = []
  attributeChangeListeners[att].push(fnc)
}

export const emitAttributeChange = (att: string, loopCount: number | undefined = 0) => {
  attributeChangeListeners[att]?.forEach((fnc: (loopCount: number) => void) => fnc(loopCount))
}

export const compareValues = (val1, val2, questionnaire_type) => {
  val1 = val1 ?? ""
  val2 = val2 ?? ""
  const compareArrays = (arr1, arr2) => {
    let result
    // compare lengths - can save a lot of time
    if (arr1.length != arr2.length)
      result = false;

    for (let i = 0, l=arr1.length; i < l; i++) {
      // Check if we have nested arr2s
      if (arr1[i] instanceof Array && arr2[i] instanceof Array) {
        // recurse into the nested arr2s
        if (!compareArrays(arr1[i], arr2[i]))
          result = false;
      }
      else if (arr1[i] != arr2[i]) {
        // Warning - two different object instances will never be equal: {x:20} != {x:20}
        result = false;
      }
    }
    result = true;
    return result
  }

  if(!['dq', 'ld'].includes(questionnaire_type)) return
  if(Array.isArray(val1) && Array.isArray(val2)) return compareArrays(val1, val2)
  else return val1 === val2
}

export const toOneBasedCount = (loopCounts: Record<string, number>) => {
  return Object.keys(loopCounts).reduce((acc, key) => {
    acc[key] = loopCounts[key] + 1
    return acc
  }, {...loopCounts})
}

export const setLoopCounts = (force = false, stateLoopCounts: Record<string, number>, newLoopCounts: Record<string, number>) => {
  if(force) return newLoopCounts
  else return Object.assign(stateLoopCounts, newLoopCounts)
}

export const getValueAndModificator = (value: string | string[], question: Backend.Questionnaire.IQuestion) => {
  const isCurrency = question.type === 'currency'
  const isMultiSelect = question.type === 'multi_select'
  const isDate = question.type === 'date'
  const isFileUpload = question.type === 'file_upload'
  const isDatasheets = question.type === 'datasheets'

  const result = {value} as {value: string | null, modificator: string}

  if(isCurrency && typeof value === 'string') {
    const newValue = value.split(currencySplitter)[0] || null
    const modificator = value.split(currencySplitter)[1] || null
    result.value = newValue === 'null' ? '' : newValue
    if(modificator && modificator !== 'undefined') result.modificator = modificator
  }

  if(isMultiSelect && Array.isArray(value)) {
    result.value = value?.join(question.opts.defaultSeparator)
  }

  if(isFileUpload && value && Array.isArray(value)) {
    result.value = value.join(',')
  }

  if(isDate && typeof value === 'string') {
    const newValue = value.split(currencySplitter)[0] || null;
    const modifier = value.split(currencySplitter)[1] || null;
    result.value = newValue === 'null' ? '' : newValue;
    if(modifier && modifier !== "undefined") result.modificator = modifier;
  }

  if (isDatasheets && typeof value === "string") {
    const [newValue, modificator] = dsSplit(value)
    result.value = newValue === 'null' || newValue === null ? '' : newValue
    result.modificator = modificator
  }

  return result
}

export const fetchLiveDemoData = ({state, getters, commit}) => {
  const params = {
    entries: state.answers,
    loop_counts: toOneBasedCount(state.loop_counts),
    asts: getters.asts,
    operations: state.operations?.map((operation) =>
      `\`${operation.att} ${operation.cond}\``).join("\n") || []
  }

  axios.post(`/templates/${AvvStore.state.template_id}/live_entries`, params)
    .then(response => response.data)
    .then(data => {
      commit('SET_CONDITION_LOGS', data.logs)
      commit('SET_RESOLVED_CONDITIONS', data.conditions)
      DOMEmit('live-demo-html-update', {html: data.html})
      state.changed_attrs = []
    })
}

export const parseMatch = (match: string, wrapInAst = true) => {
  const matchForParse = match.slice(1, -1)
  const superAst = Ast.parse(matchForParse, wrapInAst)
  if(!superAst) return null
  const ast = 'ast' in superAst ? superAst.ast : superAst
  return ast['Att_default'] as [string, string]
}
