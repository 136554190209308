import {clone} from "@avvoka/shared";
import {useTemplateVersionStore} from "@stores/generic/templateVersion.store";
import {getActivePinia} from "pinia";
import {watch, toRaw} from "vue";
import type {InjectionKey, Ref} from "vue"
import type {Store} from "vuex";
import {
  findChangedParty,
  saveEntry,
  listenOnStatusChanges,
  compareValues,
  setLoopCounts
} from "./QuestionnaireHelpers";
import Utils from "./utils";
import {getVisibilityConditionForQuestion} from "./VisibilityConditions";
import {PROCESS_STATES} from "./questionnaire/sidekiq_states";
import { currencySplitter, dsSplitter } from "./QuestionnaireHelpers";
import { toPlainText } from "./dom_utils";
import {type Datasheet, dsCellName} from "./questionnaire/types/datasheets/utils";
import {synchronizeStoreField} from "./storeHelpers";
import { DATASHEET_DIVIDER_TOKEN } from './questionnaire/types/datasheets/utils'
import { type DatasheetRowValues } from "./add_datasheet_row/types";
import {DOMEmit} from "./dom_utils";
import { fetchLiveDemoData } from "../views/templates/live-demo-utils";
import type {IOperation} from "./operations/OperationsUtilts";
import useDebounce from "./_abstract/utils/debounce";

const axios = Utils.axios

export interface ISelectOption {
  label: string
  value: string
  cond?: string
  type?: 'static' | 'collect',
  label_type?: 'static' | 'collect'
}

export interface QStoreContext {
  state: IQuestionnaireStore
  commit: any // feel free to add better typing
  dispatch: any
  getters: any
}

export type DefaultMode = 'templating' | 'templating+demo' | 'demo'

export interface IQuestionnaireStore {
  document_id: number
  documentTitle: string
  parties: string[]
  questionnaires: string[]
  questions: Backend.Questionnaire.IQuestion[]
  operations?: any[]
  mass_selected_questions: Backend.Questionnaire.QuestionID[]
  generated_questions: Backend.Questionnaire.QuestionID[]
  selected_question: Backend.Questionnaire.QuestionID
  selected_party?: string
  compress_questions: boolean
  show_template_settings: boolean
  dragging: boolean
  default_mode?: string
  answers: Record<string, string | string[]>
  display_toolbar: boolean
  show_dialog: boolean
  resolvedConditions: null | object
  documentConditions: null | object
  conditionLogs: null | object
  current_section: number
  errors: Backend.Questionnaire.Errors
  active_requests: Record<string, number | number[]>
  scheduled_requests: Record<string, number | number[]>
  questionnaire_type: string
  saved_answers: Record<string, string | string[]>[]
  removed_answers: {att: string, value: string, index: number }[]
  active_filter? : { filter: (arg0: Backend.Questionnaire.IQuestion) => boolean, text: string }
  showConditionLogic?: boolean
  changed_attrs: { att: string, loopCount: number }[],
  loop_counts: Record<string, number>
  sidekiq_status: PROCESS_STATES,
  process_sidekiq_status: boolean,
  datasheets: Datasheet[],
  datasheet_values: Record<string, string[] | undefined>,
  formattedNumbers: Record<string, string[]>,
  allow_suggested_definition: boolean,
  allow_change_q_type: boolean,
  allowSavedEntries: boolean,
  allowCreateDocument: boolean,
  allowFullscreen: boolean,
  created_from_pack: boolean
  templates_by_attributes?: Record<string, string[]>
  show_editor?: boolean
  allowUseSuggestedVC: boolean
  bShowExportAnswers: boolean
  newQuestionAsPack: boolean
  last_saved_entries: Record<string, unknown>
}

const actions = {
  selectQuestion({commit}: SmartStore<IQuestionnaireStore>, question: Backend.Questionnaire.IQuestion) {
    commit("SET_SELECTED_QUESTION", question.uuid)
  },
  toggleCompressQuestions({commit}: SmartStore<IQuestionnaireStore>) {
    commit("TOGGLE_COMPRESS_QUESTIONS")
  },
  toggleSettings({commit}: SmartStore<IQuestionnaireStore>) {
    commit("TOGGLE_SHOW_SETTINGS")
  },
  selectQuestionnaire({commit}: SmartStore<IQuestionnaireStore>, partyName: string) {
    commit("SET_MASS_SELECT", []);
    commit("SET_SELECTED_PARTY", partyName);
  },
  async on_answers_change({ commit, state, getters, dispatch } : QStoreContext, {newValue, oldValue}){
    if(state.questionnaire_type === 'ld') {
      fetchLiveDemoData({state, getters, commit, dispatch})
    } else if(state.questionnaire_type === 'dq') {
      const changed_attrs = [...state.changed_attrs]
      changed_attrs.forEach(async ({att, loopCount}) => {
        const index = state.changed_attrs.findIndex(item => item.att == att && item.loopCount == loopCount)
        state.changed_attrs.splice(index, 1)
        await dispatch('save_entry', {att, loopCount, start: true})
      })
    }
  },
  async save_entry({commit, state, dispatch}: QStoreContext, {att, loopCount, start}) {
    if(start) {
      commit('START_SAVE_ENTRY', {att, loopCount})
      if(state.process_sidekiq_status) commit('SET_SIDEKIQ_STATUS', {sidekiq_status: PROCESS_STATES.PROCESSING, immediate: true})
      if(Object.keys(state.active_requests).length === 1 && Object.values(state.active_requests).every(el => {
        if(Array.isArray(el)) return el.length < 2
        else return el < 2
      })) {
        saveEntry({state, dispatch, commit}, att, state.answers[att], loopCount - 1)
      }
    }
    else {
      commit('END_SAVE_ENTRY', {att, loopCount})
      const dependentAtts = state.questions.filter(q => {
        const dependencies = q.opts.datasheet_dependencies
        if(!dependencies) return false
        const dependsOnAttribute = dependencies.some(dep => {
          return dep.attribute == att
        })
       return dependsOnAttribute
      }).map(q => q.att)
      const promises = dependentAtts.map(dependentAtt => dispatch('fetch_datasheet_values', {att: dependentAtt, loopCount: loopCount - 1}))
      Promise.all(promises).then(() => {
        const nextAtt = Object.keys(state.active_requests)[0]
        if(nextAtt) {
          const nextAttLoopCount = state.active_requests[nextAtt][0] - 1
          saveEntry({state, dispatch, commit}, nextAtt, state.answers[nextAtt], nextAttLoopCount)
        }
      })
    }
  },
  async remove_repetition({dispatch, state, commit}: QStoreContext, {att, loopCount}: {att: string, loopCount: number | null}){
    saveEntry({state, dispatch, commit}, att, '', loopCount)
  },
  async remove_answers({state, dispatch, commit}: QStoreContext){
    state.removed_answers.forEach(({att, value, index }) => {
      saveEntry({state, dispatch, commit}, att, null, index)
    })
  },
  fetch_saved_answers({ commit }: QStoreContext){
    const documentId = AvvStore.state.document_id;

    let url = undefined;
    if(typeof documentId === "number") {
      url = `/documents/${documentId}/test_entries`
    } else {
      const template_id = AvvStore.state.template_id
      if(template_id === -1) return
      url = `/templates/${template_id}/test_entries`
    }

    axios.get(url).then(response => {
      commit('SET_SAVED_ANSWERS', response.data)
    })
  },
  fetch_entry_detail({ commit, state }: QStoreContext, id){
    const documentId = AvvStore.state.document_id;

    let url = undefined;
    if(typeof documentId === "number") {
      url = `/documents/${documentId}/test_entries/${id}`
    } else {
      const template_id = AvvStore.state.template_id
      if(template_id === -1) return
      url = `/templates/${template_id}/test_entries/${id}`
    }

    axios.get(url).then(response => {
      for (const [att, value] of Object.entries(response.data.answers)) {
        commit('ADD_ANSWER', {att, value})
        const repeater_id = state.questions.find(q => q.att === att)?.opts['repeater-id']
        if(Array.isArray(value) && repeater_id) {
          const loopCount = value.length - 1
          commit('SET_LOOP_COUNT', {repeater_id: repeater_id, value: loopCount})
        }
      }
    })
  },
  save_answers({ commit, state, dispatch }: QStoreContext, name){
    const template_id = AvvStore.state.template_id
    const url = `/templates/${template_id}/test_entries`
    const params = { template_test_entry: {allow_usage_in_documents: false, answers: state.answers, title: name} }
    axios.post(url, params)
      .then(() => dispatch('fetch_saved_answers'))
  },
  delete_saved_entry({ dispatch }: QStoreContext, id){
    const template_id = AvvStore.state.template_id
    const url = `/templates/${template_id}/test_entries/${id}`
    const config = {
      url,
      method: 'delete',
    }
    axios(config)
      .then(response => {
        if(response.status === 200) alert(localizeText('questionnaire.notices.deleted_answers'))
        else alert(localizeText('questionnaire.error'))
      })
      .then(response => dispatch('fetch_saved_answers'))
  },
  update_saved_entry({ dispatch, state }: QStoreContext, {id, allow_usage_in_documents}){
    const template_id = AvvStore.state.template_id
    const url = `/templates/${template_id}/test_entries/${id}`
    const params = { template_test_entry: {allow_usage_in_documents, answers: state.answers} }

    return axios.patch(url, params)
      .then(response => {
        if(response.status === 200) {
          avv_dialog({snackMessage: localizeText('questionnaire.updated_answers')})
        }
      })
  },
  async fetch_sidekiq_status({ commit, state }: QStoreContext){
    const url = `process_state`
    const response = await axios.get(url)
    if(response.status === 200) {
      const sidekiq_status = response.data.status
      commit('SET_SIDEKIQ_STATUS', {sidekiq_status, immediate: true})
    }
  },
  async fetch_datasheet_values({ commit, state }: QStoreContext, {att, loopCount }: {att: string, loopCount?: number}){
    return new Promise<void>(async resolve => {
      const urlParams = new URLSearchParams()
      urlParams.set('attribute', att)
      const question = state.questions.find(q => q.att === att)
      if(!question || question.type !== 'datasheets') return
      const dependencies = question.opts.datasheet_dependencies ?? []
      const properLoopCount = Utils.notANumber(loopCount) ? 0 : loopCount
      dependencies.forEach(({attribute}: {attribute: string}) => {
        const value = state.answers[attribute]
        urlParams.set(attribute, dsCellName(Array.isArray(value) ? value[properLoopCount] : value))
      })
      const documentID = AvvStore.state.documents_by_attributes[att][0] ?? AvvStore.state.document_id
      const response = await axios.get(`/documents/${documentID}/datasheet_values?${urlParams.toString()}`)
      commit('SET_DATASHEET_VALUES', {
        values: response.data.datasheet_values.map(([value, id] : Array<string>) => `${value}${DATASHEET_DIVIDER_TOKEN}${id}`),
        att,
        loopCount: properLoopCount
      })
      resolve()
    })
  },
  async reload_datasheet_answers({ commit, dispatch, state }: QStoreContext, { datasheetId, oldValues, newValues, loopCount }: { datasheetId: string, oldValues?: DatasheetRowValues, newValues?: DatasheetRowValues, loopCount?: number }) {
    // Find all questions that depend on datasheet id (must be non-strict equals due to different both numbers / strings appearing as arguments)
    const affectedQuestions = state.questions.filter(({ opts }) => opts && opts.datasheet_id == datasheetId)

    return Promise.all(affectedQuestions.map(async ({ att, opts }) => {
      // Find display header
      const answer = state.answers[att]
      const header = opts.datasheet_display_header_id
      // Fetch datasheet values for all questions and update answers if possible
      if (Array.isArray(answer)) {
        const answerLength = Utils.notANumber(loopCount) ? answer.length : Math.max(answer.length, loopCount!)
        for (let i = 0; i < answerLength; i++) {
          const update = oldValues && newValues && answer[i] === oldValues[header] && newValues[header] !== oldValues[header]
          await dispatch('fetch_datasheet_values', { att, loopCount: i })
          if (update) {
            state.answers[att][i] = newValues[header]
          }
        }
      } else {
        const update = oldValues && newValues && answer === oldValues[header] && newValues[header] !== oldValues[header]
        await dispatch('fetch_datasheet_values', { att })
        if (update) {
          state.answers[att] = newValues[header]
        }
      }
    }))
  },
  on_document_conditions_change(store: QStoreContext, value: Backend.Questionnaire.ResolvedConditionsByDoc) {
    Object.entries(value).forEach(([id, value]) => {
      const editorElement = document.querySelector(`#q-editor-${id}`)
      if (editorElement) {
        editorElement.setAttribute('data-active', value)
      }
    })
    DOMEmit('document-conditions-update')
  },
  on_resolved_conditions_change(store: QStoreContext, value) {
    const unresolvedQuestions = store.state.questions.filter((q) => {
      if(!q.att) return false
      if(!store.state.answers[q.att]) return false
      if(!q.cond) return false
      else return !value[q.cond]
    })
    unresolvedQuestions.forEach(q => {
      store.commit('REMOVE_ANSWER', {att: q.att, wipe: true})
    })
  }
}

const getters = {
  questionsByAtt(state): Record<string, Backend.Questionnaire.IQuestion> {
    return state.questions.reduce((obj, q) => (obj[q.att] = q, obj), Object.create(null))
  },
  questionByAtt: (state: IQuestionnaireStore) => (att: string): Backend.Questionnaire.IQuestion | undefined => {
    return state.questions.find(q => q.att === att)
  },
  mappedQuestions(state): Record<Backend.Questionnaire.QuestionID, Backend.Questionnaire.IQuestion> {
    return state.questions.reduce((obj, q) => (obj[q.uuid] = q, obj), Object.create(null))
  },
  massSelectedQuestions(state, getters): Backend.Questionnaire.IQuestion[] {
    return state.mass_selected_questions.reduce((arr, uuid) => (arr.push(getters.mappedQuestions[uuid]), arr), []);
  },
  generatedQuestions(state, getters): Backend.Questionnaire.IQuestion[] {
    return state.generated_questions.reduce((arr, uuid) => (arr.push(getters.mappedQuestions[uuid]), arr), []);
  },
  selectedQuestion(state, getters): Backend.Questionnaire.IQuestion | null {
    return getters.mappedQuestions[state.selected_question]
  },
  partyQuestions(state): Record<string, Backend.Questionnaire.IQuestion[]> {
    const result = state.questions.reduce((acc, el) =>{
      if(!acc[el.party]) acc[el.party] = [];
      acc[el.party].push(el);
      return acc;
    }, Object.create(null))
    state.questionnaires.forEach(p => {
      if(result[p] == null) result[p] = []
    })
    return result;
  },
  questionAndOperationAsts(state: IQuestionnaireStore){
    const visConds = state.questions.map(q => q.cond).filter(e => e)
    const operationAsts = state.operations?.map(o => o.cond).filter(e => e) || []
    const optionsVisConds = state.questions.map(q => q.opts.selectOptions?.map(opt => opt.cond)).flat().filter(e => e)
    return Ast.uniqueArray(visConds.concat(operationAsts).concat(optionsVisConds))
  },
  sidekiq_done(state: IQuestionnaireStore){
    return state.sidekiq_status == PROCESS_STATES.DONE
  },
  non_dependent_and_dependent_resolved_datasheet_atts(state: IQuestionnaireStore, getters){
    const hasNoUnresolvedDependency = (q: Backend.Questionnaire.IQuestion) => !q.opts.datasheet_dependent_attribute || getters.hasAnswer(q.opts.datasheet_dependent_attribute)

    const isDatasheetQuestion = (q: Backend.Questionnaire.IQuestion) => q.type === 'datasheets'
    return state.questions.filter(isDatasheetQuestion).filter(hasNoUnresolvedDependency).map(q => q.att)
  },
  hasAnswer(state: IQuestionnaireStore){
    return att => {
      const answer = state.answers[att]
      const isEmptyArray = (val) => {
        if(Array.isArray(val)) {
          return val.length === 0
        } else return false
      }
      if(!answer || isEmptyArray(answer)) return false
      else return true
    }
  }
}

const mutations = {
  SET_DRAGGING(state: IQuestionnaireStore, value: boolean) {
    state.dragging = value;
  },
  SET_SELECTED_PARTY(state: IQuestionnaireStore, partyName: string) {
    state.selected_party = partyName
  },
  CREATE_QUESTIONNAIRE(state: IQuestionnaireStore, partyName: string) {
    if(state.parties.indexOf(partyName) === -1) throw new Error("Trying to create questionnaire for not existing party.")
    state.questionnaires.push(partyName);
  },
  ADD_QUESTIONS(state: IQuestionnaireStore, {index = -1, data}: {index: number, data: Backend.Questionnaire.IQuestion[]}) {
    const withoutDuplicates = data.filter((q: Backend.Questionnaire.IQuestion, i: number, self: Backend.Questionnaire.IQuestion[]) => self.findIndex(dq => {
      if(['section', 'label'].includes(q.type)) return toPlainText(q.desc) == toPlainText(dq.desc)
      return dq.att === q.att
    }) === i)
    withoutDuplicates.forEach(question => {
      if(!question.cond) question.cond = getVisibilityConditionForQuestion(question, EditorFactory.main)
      // if nothing was found, remove the key
      if(!question.cond) delete question.cond
    })
    if(index === -1) {
      withoutDuplicates.map(q => q.att).forEach(att => {
        const questionIndex = state.questions.findIndex(q => q.att === att)
        if(questionIndex >= 0) state.questions.splice(questionIndex, 1)
      })
      state.questions.push(...withoutDuplicates);
    }
    else state.questions.splice(index, 0, ...withoutDuplicates);
  },
  REMOVE_QUESTION(state: IQuestionnaireStore, {data}: {data: number | Backend.Questionnaire.IQuestion}) {
    if(typeof data === "number") state.questions.splice(data, 1)
    else {
      const index = state.questions.findIndex(i => i === data)
      if(index === -1) throw new Error("Trying to remove content what doesn't exist at questionnaire")
      state.questions.splice(index, 1)
    }
  },
  REMOVE_QUESTIONS(state: IQuestionnaireStore, {data}: {data: Array<Backend.Questionnaire.IQuestion>}) {
    data.forEach(q => {
      const index = state.questions.findIndex(i => i === q)
      if(index === -1) throw new Error("Trying to remove content what doesn't exist at questionnaire")
      state.questions.splice(index, 1)
    })
  },
  SET_QUESTIONS(state: IQuestionnaireStore, {data}: {data: Array<Backend.Questionnaire.IQuestion>}) {
    state.questions.splice(0, state.questions.length)
    state.questions.push(...data)
  },
  REPLACE_QUESTION(state: IQuestionnaireStore, {oldQuestion, newQuestion}: {oldQuestion: Backend.Questionnaire.IQuestion, newQuestion: Backend.Questionnaire.IQuestion}) {
    if(oldQuestion.uuid === newQuestion.uuid) {
      const differentTypes = oldQuestion.type !== newQuestion.type
      Object.entries(newQuestion).forEach(([key, value]) => {
        oldQuestion[key as keyof Backend.Questionnaire.IQuestion] = value;
      })
      if(differentTypes) oldQuestion.opts.default = ""
      return;
    }

    const index = state.questions.findIndex(({uuid}) => uuid === oldQuestion.uuid)
    if(index === -1) {
      console.error("Trying to update question what wasn't found.")
    } else {
      state.questions.splice(index, 1, newQuestion)
    }
  },
  MOVE_QUESTIONS(state: IQuestionnaireStore, {refIndex, questions}: {refIndex: number, questions: Array<Backend.Questionnaire.IQuestion>}) {
    if(refIndex < 0 || questions.length === 0) {
      console.error("Wrong arguments for MOVE_QUESTION");
      return;
    }

    questions.sort((a, b) => state.questions.indexOf(a) - state.questions.indexOf(b))

    questions.forEach(q => {
      const index = state.questions.indexOf(q);
      if(index === -1) {
        console.error("Index was not found for question", q)
        return;
      }
      state.questions.splice(index, 1);
    })

    state.questions.splice(refIndex, 0, ...questions)
  },
  SET_MASS_SELECT(state: IQuestionnaireStore, payload: Array<Backend.Questionnaire.QuestionID>) {
    state.mass_selected_questions = payload;
  },
  SET_GENERATED(state: IQuestionnaireStore, payload: Array<Backend.Questionnaire.QuestionID>) {
    state.generated_questions = payload;
  },
  SET_SELECTED_QUESTION(state: IQuestionnaireStore, payload: Backend.Questionnaire.QuestionID) {
    state.selected_question = payload
  },
  TOGGLE_COMPRESS_QUESTIONS(state: IQuestionnaireStore) {
    state.compress_questions = !state.compress_questions
  },
  TOGGLE_SHOW_SETTINGS(state: IQuestionnaireStore) {
    state.show_template_settings = !state.show_template_settings
  },
  SET_DEFAULT_MODE(state: IQuestionnaireStore, defaultMode: string){
    state.default_mode = defaultMode
  },
  ADD_ANSWER(state: IQuestionnaireStore, {value, att, loopCount = -1, type}: {value: string, att: string, loopCount: number, type?: string}){
    loopCount = loopCount ? loopCount - 1 : loopCount
    const isArrayQType = ['file_upload', 'multi_select'].includes(type ?? '')
    const isInsideRepeater = loopCount >= 0

    if(isInsideRepeater) {
      if(!state.answers[att]) state.answers[att] = []
      if(isArrayQType) {
        if(!Array.isArray(value)){
          if(!state.answers[att][loopCount]) state.answers[att][loopCount] = []

          // replace the initial empty value:
          if (state.answers[att][loopCount].length === 1 && state.answers[att][loopCount][0] === "") state.answers[att][loopCount][0] = value
          else state.answers[att][loopCount].push(value)
          state.changed_attrs.push({att, loopCount: loopCount + 1})
        }
      } else {
        if(!compareValues(state.answers[att][loopCount], value, state.questionnaire_type)) {
          state.answers[att][loopCount] = value
          state.changed_attrs.push({att, loopCount: loopCount + 1})
        }
      }
    }
    else {
      if(isArrayQType) {
        if(!Array.isArray(value)){
          if(!state.answers[att]) state.answers[att] = []

          // replace the initial empty value:
          if (state.answers[att].length === 1 && state.answers[att][0] === "") state.answers[att][0] = value
          else state.answers[att].push(value)

          state.changed_attrs.push({att, loopCount: loopCount + 1})
        }
      }
      else if(!compareValues(state.answers[att], value, state.questionnaire_type)) {
        state.answers[att] = value
        state.changed_attrs.push({att, loopCount: loopCount + 1})
      }
    }
  },
  ADD_LAST_SAVED_ENTRY(state: IQuestionnaireStore, {value, att, loopCount}: {value: string, att: string, loopCount?: number}){
    const type = state.questions.find(q => q.att === att).type
    loopCount = loopCount ? loopCount - 1 : loopCount
    const isArrayQType = ['file_upload', 'multi_select'].includes(type)
    const isCurrencyWithModificator = type === 'currency' && value.split(currencySplitter)[1]

    const isInsideRepeater = !Utils.notANumber(loopCount)
    if(isInsideRepeater) {
      if(!state.last_saved_entries[att]) state.last_saved_entries[att] = []
      if(isArrayQType) {
        if(!Array.isArray(value)){
          if(!state.last_saved_entries[att][loopCount]) state.last_saved_entries[att][loopCount] = []
          state.last_saved_entries[att][loopCount].push(value)
        }
      } else {
          if(isCurrencyWithModificator) {
            const modificator = value.split(currencySplitter)[1]
            state.last_saved_entries[att] = state.last_saved_entries[att].map(entry => {
              const currVal = entry.split(currencySplitter)[0]
              return `${currVal}${currencySplitter}${modificator}`
            })
          }
          state.last_saved_entries[att][loopCount] = value

      }
    }
    else {
      if(isArrayQType) {
        if(!Array.isArray(value)){
          if(!state.last_saved_entries[att]) state.last_saved_entries[att] = []
          state.last_saved_entries[att].push(value)
        }
      }
      else state.last_saved_entries[att] = value
    }
  },
  REMOVE_ANSWER(state: IQuestionnaireStore, {value, att, wipe = false, type = null, loopCount}: {value: any, att: string, wipe?: boolean, type?: string, loopCount?: number}){
    // expects 1-based loop count
    loopCount = loopCount ? loopCount - 1 : loopCount
    if(wipe || !Array.isArray(state.answers[att]) ) delete state.answers[att]
    else {
      const question = state.questions.find(q => q.att === att)
      const isInsideRepeater = question.opts?.['repeater-id']
      const isMultiSelect = question.type === 'multi_select'

      if (isInsideRepeater) {
        if(isMultiSelect) {
          state.answers[att][loopCount] = state.answers[att][loopCount].filter(a => a !== value)
        } else {
          state.answers[att].splice(loopCount, 1)
        }
        if (!isMultiSelect) state.removed_answers.push({att, value, index})
      } else if(!isMultiSelect) state.answers[att].splice(index, 1)
      else state.answers[att] = state.answers[att].filter(a => a !== value)
    }
    state.changed_attrs.push({att, loopCount})
  },
  SET_ANSWERS(state: IQuestionnaireStore, answers){
    state.answers = answers
  },
  SET_ATT_ANSWERS(state, entries){
    Object.entries(entries).forEach(([att, value]) => {
      const question = state.questions.find(q => q.att == att)
      if(question){
        const isNumberType = question.type === 'number'
        const isLoop = question.opts['repeater-id'] != null
        state.answers[att] = isLoop ? value.value : value.value[0]
        if(isNumberType) isLoop ? value.localized_value : value.localized_value[0]
      }
    })
  },
  SET_PARTIES(state: IQuestionnaireStore, parties){
    const normalizedParties = parties.map(party => Array.isArray(party) ? party[2] : party)
    state.parties = normalizedParties
  },
  ON_PARTIES_CHANGE(state: IQuestionnaireStore, newParties){
    const oldPartiesNames = state.parties
    const newPartiesNames = newParties.map(party => Array.isArray(party) ? party[2] : party) as string[]

    state.parties = newPartiesNames

    const bPartyWasRemoved = oldPartiesNames.length > newPartiesNames.length

    if(bPartyWasRemoved) return
    const changedPartyNames = findChangedParty(oldPartiesNames, newPartiesNames)
    if(!changedPartyNames) return
    if(typeof changedPartyNames === 'string' && !state.questionnaires.includes(changedPartyNames)) state.questionnaires.push(changedPartyNames)
    else if(typeof changedPartyNames !== 'string') {
      const index = state.questionnaires.indexOf(changedPartyNames.oldPartyName)
      if(state.questionnaires.includes(changedPartyNames.newPartyName)) state.questionnaires.splice(index, 1)
      else state.questionnaires.splice(index, 1, changedPartyNames.newPartyName)
      state.questions.filter(q => q.party === changedPartyNames.oldPartyName)
        .forEach(question => question.party = changedPartyNames.newPartyName)
      if(state.selected_party === changedPartyNames.oldPartyName) state.selected_party = changedPartyNames.newPartyName
    }
  },
  DELETE_QUESTIONNAIRE(state: IQuestionnaireStore, name){
    const index = state.questionnaires.indexOf(name)
    state.questionnaires.splice(index, 1)
  },
  SET_SHOW_DIALOG(state: IQuestionnaireStore, val){
    state.show_dialog = val
  },
  SET_RESOLVED_CONDITIONS(state: IQuestionnaireStore, conds) {
    state.resolvedConditions = conds ?? {}
  },
  SET_DOCUMENT_CONDITIONS(state: IQuestionnaireStore, conds){
    state.documentConditions = conds ?? {}
  },
  SET_CONDITION_LOGS(state: IQuestionnaireStore, logs){
    state.conditionLogs = logs ?? {}
  },
  SET_OPERATIONS(state: IQuestionnaireStore, operations) {
    state.operations = operations
  },
  SET_CURRENT_SECTION(state: IQuestionnaireStore, section) {
    state.current_section = typeof section === 'string' ? state.questions.filter(q => q.type === 'section').map(q => q.desc).indexOf(section) : isNaN(section) ? -1 : section
  },
  START_SAVE_ENTRY(state: IQuestionnaireStore, {att, loopCount}: {att: string, loopCount?: number}) {
    const isRepeater = loopCount != null && !isNaN(loopCount)
    if(isRepeater) {
      if(!Array.isArray(state.active_requests[att])) state.active_requests[att] = []
      if(!state.active_requests[att].includes(loopCount))state.active_requests[att].push(loopCount)
    } else {
      if(typeof state.active_requests[att] == 'undefined') {
        state.active_requests[att] = 1
      } else state.active_requests[att]++
    }
  },
  END_SAVE_ENTRY(state: IQuestionnaireStore, {att, loopCount}: {att: string, loopCount?: number}) {
    const isRepeater = loopCount != null && !isNaN(loopCount)
    if(isRepeater){
      if(Array.isArray(state.active_requests[att])) state.active_requests[att].shift()
      if(!state.active_requests[att]?.length) delete state.active_requests[att]
    } else {
      state.active_requests[att]--
      if(!state.active_requests[att]) delete state.active_requests[att]
    }
  },
  ADD_SCHEDULED_REQUEST(state: IQuestionnaireStore, {att, loopCount}: {att: string, loopCount?: number}) {
    const isRepeater = loopCount != null && !isNaN(loopCount)
    if(isRepeater) {
      if(!Array.isArray(state.scheduled_requests[att])) state.scheduled_requests[att] = []
      state.scheduled_requests[att].push(loopCount)
    } else {
      if(typeof state.scheduled_requests[att] == 'undefined') {
        state.scheduled_requests[att] = 1
      } else state.scheduled_requests[att]++
    }
  },
  REMOVE_SCHEDULED_REQUEST(state: IQuestionnaireStore, {att, loopCount}: {att: string, loopCount?: number}) {
    const isRepeater = loopCount != null && !isNaN(loopCount)
    if(isRepeater){
      state.scheduled_requests[att]?.shift()
      if(!state.scheduled_requests[att]?.length) delete state.scheduled_requests[att]
    } else {
      state.scheduled_requests[att]--
      if(!state.scheduled_requests[att]) delete state.scheduled_requests[att]
    }
  },
  SET_SAVED_ANSWERS(state: IQuestionnaireStore, answers){
    state.saved_answers = state.questionnaire_type === 'ld' ? answers : answers.filter(a => a.allow_usage_in_documents)
  },
  SET_ACTIVE_FILTER(state: IQuestionnaireStore, filter){
    state.mass_selected_questions = []
    state.active_filter = filter
  },
  SET_CONDITION_LOGIC(state: IQuestionnaireStore, val){
    state.showConditionLogic = val
  },
  SET_LOOP_COUNT(state: IQuestionnaireStore, {value, repeater_id}){
    state.loop_counts[repeater_id] = value
    const linkedRepeaters = Ast.uniqueArray(state.questions.filter(q => q.opts['repeater-master-id'] === repeater_id).map(q => q.opts['repeater-id']))
    linkedRepeaters.forEach(repeater => {
      state.loop_counts[repeater as string] = value
    })
  },
  SET_SIDEKIQ_STATUS(state: IQuestionnaireStore, {sidekiq_status, immediate}: {sidekiq_status: PROCESS_STATES, immediate: boolean}){
    useDebounce(() => {
      state.sidekiq_status = sidekiq_status
    }, 500, immediate)
  },
  UPDATE_DQ_LIVE(state: IQuestionnaireStore, data){
    if (state.trigger_browser) {
      // We do not want to update the answers in the browser that triggered the change in the select type because it would close the opened select box
      state.trigger_browser = false
    } else {

      data.changeset.forEach((change) => {
        const question = state.questions.find(q => q.att === change.name)
        const active_request = state.active_requests[change.name]
        const scheduled_request = state.scheduled_requests[change.name]
        if (!question || active_request || scheduled_request) return

        const isLoop = typeof change.succession === 'number'
        let newAnswer = change.value ?? ''

        if (isLoop && !Array.isArray(state.answers[change.name])) state.answers[change.name] = []

        switch (question.type) {

          case 'multi_select':
            let newAnswerArray = []
            const separator = question.opts.defaultSeparator

            if (separator !== '' && newAnswer.includes(separator)) newAnswerArray = newAnswer.split(separator)
            newAnswer = newAnswerArray.length > 0 ? newAnswerArray : [newAnswer]

            break;

          case 'currency':
            newAnswer = newAnswer + currencySplitter + change.modificator
            break;

          case 'datasheets':
            const isDependent = question.opts.datasheet_dependencies?.length > 0
            const hasModificator = ["string", "number"].includes(typeof change.modificator)

            newAnswer = hasModificator ? (newAnswer + dsSplitter + change.modificator) : newAnswer
            const newDsValEmpty = newAnswer === ''

            const dsValuesIndex = isLoop ? change.succession : 0

            if (!state.datasheet_values[change.name]) {
              state.datasheet_values[change.name] = []
            }

            const overwriteList = state.datasheet_values[change.name][dsValuesIndex]?.length <= 1

            if (isDependent && hasModificator && overwriteList) state.datasheet_values[change.name][dsValuesIndex] = [newAnswer]
            if (isDependent && newDsValEmpty && overwriteList) state.datasheet_values[change.name][dsValuesIndex]?.pop()

            if (hasModificator && !overwriteList && !state.datasheet_values[change.name][dsValuesIndex].includes(newAnswer)) {
              const dsToEdit = state.datasheet_values[change.name][dsValuesIndex].filter((ds) => ds.split(dsSplitter)[1] === change.modificator.toString())

              if (dsToEdit.length > 0) {
                // means we are editing a value:
                const dsIndex = state.datasheet_values[change.name][dsValuesIndex].indexOf(dsToEdit[0])
                state.datasheet_values[change.name][dsValuesIndex][dsIndex] = newAnswer

              } else state.datasheet_values[change.name][dsValuesIndex].push(newAnswer)
            }

            break;
        }

        // When the default value is the same as the new answer, we set the new answer to empty
        if(question.opts?.default && question.opts?.default == newAnswer && question.type !== 'number') {
          newAnswer = ''
        }

        if (isLoop) {
          state.answers[change.name][change.succession] = newAnswer
        } else {
          state.answers[change.name] = newAnswer
        }
      })

      setLoopCounts(!state.created_from_pack, state.loop_counts, data.data.loop_counts)
      state.resolvedConditions = data.data.valid_conditions
      state.formattedNumbers = data.data.entries_values_localized
    }
  },
  SET_DATASHEETS(state: IQuestionnaireStore, datasheets) {
    state.datasheets = datasheets
  },
  SET_DATASHEET_VALUES(state: IQuestionnaireStore, {values, att, loopCount = 0}) {
    if(!state.datasheet_values[att]) state.datasheet_values[att] = []
    const index = Utils.notANumber(loopCount) ? 0 : loopCount
    state.datasheet_values[att][index] = values
  },
  SET_LOCALIZED_VALUE(state: IQuestionnaireStore, {value, att, loopCount}: {value: string, att: string, loopCount?: number}) {
    if(!state.formattedNumbers[att]) state.formattedNumbers[att] = []
    state.formattedNumbers[att][loopCount ? loopCount : 0] = value
  },
  SET_QUESTIONNAIRE_DATA(state: IQuestionnaireStore, questionnaireData){
    const { valid_conditions, entries_values, entries_values_localized, loop_counts, document_conditions, title } = questionnaireData
    if(loop_counts) state.loop_counts = setLoopCounts(!state.created_from_pack, state.loop_counts, loop_counts)
    if(valid_conditions) state.resolvedConditions = valid_conditions
    if(entries_values_localized) state.formattedNumbers = entries_values_localized
    if(document_conditions) state.documentConditions = document_conditions
    if(title) state.documentTitle = title

    // Inject operation values
    for (const [key, val] of Object.entries(entries_values ?? {})) {
      if (state.operations.find((op) => op.att === key)) {
        const stringifiedValue = Utils.stringifyValues(val)
        if(!compareValues(toRaw(state.answers[key]), stringifiedValue, state.questionnaire_type)) {
          state.answers[key] = stringifiedValue
        }
      }
    }
  },
  SET_SHOW_EDITOR(state: IQuestionnaireStore, val){
    state.show_editor = val
  },
  SET_QUESTION_OPTIONS (state: IQuestionnaireStore, { att, options }) {
    const question = state.questions.find((question) => question.att === att)
    if (question) {
      question.opts.selectOptions = options
    }
  },
  SET_ERRORS(state: IQuestionnaireStore, errors: Backend.Questionnaire.Errors){
    state.errors = errors
  }
}

export const createQuestionnaireStore = ({
                                           questionnaires,
                                           questions,
                                           operations,
                                           defaultMode = 'templating',
                                           displayToolbar = true,
                                           documentID = undefined,
                                           documentTitle = localizeText('general.not_found'),
                                           answers = {},
                                           errors = {},
                                           resolvedConditions = undefined,
                                           documentConditions = undefined,
                                           loop_counts,
                                           questionnaire_type = 'obq',
                                           questionnaire_compress = false,
                                           documentPreview = true,
                                           sidekiq_status = PROCESS_STATES.DONE,
                                           process_sidekiq_status = false,
                                           profile_id,
                                           formattedNumbers,
                                           is_doc_negotiable,
                                           allow_suggested_definition = true,
                                           allow_change_q_type = true,
                                           allow_save = false,
                                           allow_load = false,
                                           created_from_pack = false,
                                           templates_by_attributes = undefined,
                                           open_ai_integration = false,
                                           allowSavedEntries = true,
                                           allowCreateDocument = false,
                                           allowFullscreen = false,
                                           allowUseSuggestedVC = true,
                                           bShowExportAnswers = false,
                                           newQuestionAsPack = false,
                                          fastTpq = false}: {
                                          questionnaires: Ref<string[]>,
                                          questions: Ref<Backend.Questionnaire.IQuestion[]>,
                                          operations?: IOperation[],
                                          defaultMode?: DefaultMode,
                                          displayToolbar?: boolean,
                                          documentID?: number | undefined,
                                          documentTitle: string,
                                          answers?: Object,
                                          errors?: Object,
                                          resolvedConditions?: Record<string, boolean> | Array<string>,
                                          documentConditions?: Record<number, boolean>,
                                          questionnaire_type?: string,
                                          questionnaire_compress: boolean,
                                          documentPreview?: boolean,
                                          sidekiq_status?: string,
                                          process_sidekiq_status?: boolean,
                                          profile_id: number,
                                          formattedNumbers?: Record<string, string[]>,
                                          is_doc_negotiable?: boolean,
                                          allow_suggested_definition: boolean,
                                          allow_change_q_type: boolean,
                                          allow_save: boolean,
                                          allow_load: boolean,
                                          created_from_pack?: boolean,
                                          loop_counts?: Record<string, number>,
                                          templates_by_attributes?: Record<string, string[]>,
                                          open_ai_integration?: boolean,
                                          allowSavedEntries?: boolean,
                                          allowCreateDocument?: boolean,
                                          allowFullscreen?: boolean,
                                          allowUseSuggestedVC?: boolean,
                                          bShowExportAnswers?: boolean,
                                          newQuestionAsPack: boolean,
                                          fastTpq: boolean
                                        }) => {
  const store = window.Vuex.createStore<IQuestionnaireStore>({
    state: {
      document_id: documentID,
      documentTitle: documentTitle,
      profile_id: profile_id,
      parties: [],
      questionnaires: clone(toRaw(questionnaires.value)),
      questions: clone(toRaw(questions.value)),
      operations: operations,
      mass_selected_questions: [],
      generated_questions: [],
      selected_question: null,
      selected_party: questionnaires.value[0],
      compress_questions: questionnaire_compress,
      show_template_settings: false,
      dragging: false,
      default_mode: defaultMode,
      answers: answers,
      last_saved_entries: {},
      display_toolbar: displayToolbar,
      show_dialog: false,
      resolvedConditions: resolvedConditions,
      documentConditions: documentConditions,
      current_section: -1,
      errors,
      active_requests: {},
      scheduled_requests: {},
      sidekiq_status: sidekiq_status,
      process_sidekiq_status: process_sidekiq_status,
      questionnaire_type: questionnaire_type,
      saved_answers: [],
      removed_answers: [],
      active_filter: null,
      showConditionLogic: false,
      changed_attrs: [],
      loop_counts: loop_counts,
      datasheet_values: {},
      formattedNumbers: formattedNumbers,
      is_doc_negotiable: is_doc_negotiable,
      allow_change_q_type: allow_change_q_type,
      allow_suggested_definition: allow_suggested_definition,
      allow_save: allow_save,
      allow_load: allow_load,
      created_from_pack,
      templates_by_attributes,
      conditionLogs: {},
      show_editor: documentPreview,
      open_ai_integration,
      allowSavedEntries,
      allowCreateDocument,
      allowFullscreen,
      allowUseSuggestedVC,
      bShowExportAnswers,
      newQuestionAsPack,
      fastTpq,
    },
    actions: actions as any,
    getters,
    mutations,
  });
  store.watch((state) => state.questions, (value) => { questions.value = clone(toRaw(value));}, {deep: true})
  store.watch((state) => state.questionnaires, (value) => { questionnaires.value = clone(toRaw(value));}, {deep: true})
  store.watch((state) => state.answers, (newValue, oldValue) => store.dispatch('on_answers_change', {newValue, oldValue}), { deep: true})
  store.watch((state) => state.removed_answers, (value) => store.dispatch('remove_answers'), {deep: true})
  store.watch((state) => state.documentConditions, (value) => store.dispatch('on_document_conditions_change', value), {deep: true})

  if(store.state.questionnaire_type === 'ld') {
    store.dispatch('on_answers_change', {})
    store.watch((state) => state.resolvedConditions, (value) => store.dispatch('on_resolved_conditions_change', value), {deep: true})
  }
  if(store.state.questionnaire_type === 'dq') {
    store.getters.non_dependent_and_dependent_resolved_datasheet_atts.forEach((att: string) => {
      const answers = store.state.answers[att]
      const loopAmount = Array.isArray(answers) ? answers.length : undefined
      if(loopAmount) {
        for(let loopCount = 0; loopCount < loopAmount; loopCount++) {
          store.dispatch('fetch_datasheet_values', {att, loopCount})
        }
      } else {
        store.dispatch('fetch_datasheet_values', {att})
      }
    })
  }

  if(store.state.process_sidekiq_status) {
    store.dispatch('fetch_sidekiq_status')
  }

  listenOnStatusChanges(store)

  if(window.AvvStore) {
    const templateVersionStore = useTemplateVersionStore(getActivePinia())
    if(templateVersionStore.hydrated) {
      watch(() => [...templateVersionStore.parties], (parties) => {
        store.commit('ON_PARTIES_CHANGE', parties)
      })
    }

    synchronizeStoreField([AvvStore, store], 'datasheets')
    if(store.state.questionnaire_type === 'dq') {
      AvvStore.state.related_documents.forEach(doc => {
        listenOnStatusChanges(store, doc.id)
      })
    }
  }
  window.qStore = store
  return store;
}

globalThis.createQuestionnaireStore = createQuestionnaireStore

export interface SmartStore<T> extends Omit<Store<T>, "dispatch" | "commit" | "getters"> {
  dispatch<ActionType extends keyof typeof actions>(action: ActionType, payload?: Parameters<typeof actions[ActionType]>[1]): void
  commit<MutationType extends keyof typeof mutations>(mutation: MutationType, payload?: Parameters<typeof mutations[MutationType]>[1]): void
  getters: {[P in keyof typeof getters]: ReturnType<typeof getters[P]>}
}

export const QUESTIONNAIRE_STORE: InjectionKey<SmartStore<IQuestionnaireStore>> = "QuestionnaireStore" as any
globalThis.QUESTIONNAIRE_STORE = QUESTIONNAIRE_STORE

declare global {
  let QUESTIONNAIRE_STORE: InjectionKey<SmartStore<IQuestionnaireStore>>
  interface Window {
    QUESTIONNAIRE_STORE: InjectionKey<SmartStore<IQuestionnaireStore>>
    qStore: SmartStore<IQuestionnaireStore>
  }
}
