import { Query } from '@avvoka/editor'
import type { Blot, MountedEditor } from '@avvoka/editor'
import type { AstType } from './ast/ast'

export const NO_VIS_COND = undefined
export const NO_VIS_COND_HUMAN = globalThis?.localizeText?.(
  'questionnaire.vis_cond.no_vis_cond'
)

export function getVisibilityConditionForQuestion(
  question: Backend.Questionnaire.IQuestion,
  editor: MountedEditor
): string | undefined {
  if (!question.att) return NO_VIS_COND
  const attributeInDocument = findAttributeInDocument(question.att, editor)
  const separateAsts = attributeInDocument.map((blot) =>
    getVisibilityConditionForAttribute(blot)
  )
  const shouldBeNull =
    separateAsts.some((ast) => !ast) || separateAsts.length === 0
  if (shouldBeNull) return NO_VIS_COND
  return window.Ast.stringify(combineAST(separateAsts, 'Or'))
}

export function getVisibilityConditionForAttribute(att: Blot): AstType {
  const allParentConditions = findAllParentConditions(att).map((blot) =>
    window.Ast.parse(blot.attributes['data-condition'])
  )
  const isNested = allParentConditions.length !== 0
  if (!isNested) return null
  else return combineAST(allParentConditions, 'And')
}

function findAllParentConditions(blot: Blot): Array<Blot> {
  const conditions: Array<Blot> = []
  blot = blot.parent
  while (blot.statics.blotName !== 'scroll') {
    if (
      blot.statics.blotName === 'condition' ||
      blot.statics.blotName === 'icondition'
    )
      conditions.push(blot)
    blot = blot.parent
  }
  return conditions
}

function findAllAttributes(editor: MountedEditor): Array<Blot> {
  return editor.query(Query('placeholder', 'condition', 'icondition')) as Blot[]
}

function findAttributeInDocument(
  attributeName: string,
  editor: MountedEditor
): Blot[] {
  const allAttributesBlots = findAllAttributes(editor)
  return allAttributesBlots.filter((attributeObj) => {
    if (attributeObj.statics.blotName !== 'placeholder') {
      const parsedAst = window.Ast.parse(
        attributeObj.attributes['data-condition']
      )
      if (!parsedAst) return false
      const attributes = window.Ast.traverse(parsedAst).attributes
      return attributes.includes(attributeName)
    } else {
      const attribute = attributeObj.node.textContent
      return attribute === attributeName
    }
  })
}

function combineAST(astARR, logicOperator: 'And' | 'Or') {
  if (astARR.length === 0) return undefined
  if (astARR.length === 1) return astARR[0]
  let fullAST
  if (logicOperator === 'Or') fullAST = { ast: { Or: [] } }
  else fullAST = { ast: { And: [] } }
  for (let i = 0; i < astARR.length; i++) {
    if (astARR[i] == undefined) {
      fullAST = astARR[i]
      break
    }
    if ('ast' in astARR[i]) astARR[i] = astARR[i].ast
    if (
      fullAST.ast[logicOperator].some((ast) =>
        window.Ast.compare(ast, astARR[i])
      )
    )
      continue
    fullAST.ast[logicOperator].push(astARR[i])
  }
  if (fullAST && fullAST.ast[logicOperator].length === 1)
    fullAST.ast = fullAST.ast[logicOperator][0]
  return fullAST
}
